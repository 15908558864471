var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manage"},[_c('div',{staticClass:"tabs"},[_c('el-tabs',{attrs:{"stretch":"","type":"card"},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"挂牌采购","name":"first"}},[_c('div',{staticClass:"box-block"},[_c('div',{staticClass:"block"},_vm._l((this.guapaicaigou),function(item,index){return _c('div',{key:index,staticClass:"divBlock",class:{ divBlockActive: index == 0 },on:{"click":function($event){return _vm.guapaicaigouClick(item)}}},[_c('div',{staticClass:"divBlock_header"},[_vm._v(_vm._s(item.enterpriseName))]),_c('div',{staticClass:"huowu"},[_c('div',{staticClass:"dun"},[_c('div',{staticClass:"goods-name",attrs:{"title":item.goodsName}},[_vm._v(_vm._s(item.goodsName))]),_c('div',{staticClass:"nums"},[_vm._v(" "+_vm._s(item.ifConsultRealityCount == 0 ? item.stockCount + "吨" : "以实际结算数量为准")+" ")])]),(
                    item.goodsCategoryList.length === 1 &&
                    item.goodsCategoryList[0].goodsPrice != '0.00'
                  )?_c('div',{staticClass:"money"},[_vm._v(" ￥"+_vm._s(item.goodsCategoryList[0].goodsPrice)+" ")]):_vm._e(),(
                    item.goodsCategoryList.length === 1 &&
                    item.goodsCategoryList[0].goodsPrice == '0.00'
                  )?_c('div',{staticClass:"money"},[_vm._v(" ￥面议 ")]):_vm._e(),(item.goodsCategoryList.length > 1)?_c('div',{staticClass:"money"},[_vm._v(" 详情查看 ")]):_vm._e()]),_c('div',{staticClass:"address"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.originPlace,"placement":"top-start"}},[_c('i',{staticClass:"hy-icon-shouhuodizhi"})]),_vm._v(" "+_vm._s(item.originPlace)+" ")],1),(
                  (item.stockCount == 0 || item.goodsStatus == 1) &&
                  item.ifConsultRealityCount == 0
                )?_c('div',{staticClass:"display"},[_c('div',{staticClass:"text"},[_vm._v("已过期")])]):_vm._e()])}),0)]),_c('div',{staticClass:"pagination"},[_c('el-row',{staticStyle:{"height":"50px"},attrs:{"type":"flex","justify":"end","align":"middle"}},[_c('el-pagination',{attrs:{"background":"","layout":"total,  prev, pager, next, jumper","total":_vm.get_dto.total,"current-page":_vm.get_dto.page,"page-size":_vm.get_dto.size,"page-sizes":[10, 20, 50],"pagerCount":5},on:{"current-change":_vm.changePage,"size-change":_vm.handleSizeChange}})],1)],1)]),_c('el-tab-pane',{attrs:{"label":"挂牌销售","name":"second"}},[_c('div',{staticClass:"box-block"},[_c('div',{staticClass:"block"},_vm._l((this.guapaixiaoshou),function(item,index){return _c('div',{key:index,staticClass:"divBlock",on:{"click":function($event){return _vm.guapaixiaoshouClick(item)}}},[_c('div',{staticClass:"divBlock_header"},[_vm._v(_vm._s(item.enterpriseName))]),_c('div',{staticClass:"huowu"},[_c('div',{staticClass:"dun"},[_c('div',{staticClass:"goods-name",attrs:{"title":item.goodsName}},[_vm._v(_vm._s(item.goodsName))]),_c('div',{staticClass:"nums"},[_vm._v(" "+_vm._s(item.ifConsultRealityCount == 0 ? item.stockCount + "吨" : "以实际结算数量为准")+" ")])]),(
                    item.goodsCategoryList.length === 1 &&
                    item.goodsCategoryList[0].goodsPrice != '0.00'
                  )?_c('div',{staticClass:"money"},[_vm._v(" ￥"+_vm._s(item.goodsCategoryList[0].goodsPrice)+" ")]):_vm._e(),(
                    item.goodsCategoryList.length === 1 &&
                    item.goodsCategoryList[0].goodsPrice == '0.00'
                  )?_c('div',{staticClass:"money"},[_vm._v(" ￥面议 ")]):_vm._e(),(item.goodsCategoryList.length > 1)?_c('div',{staticClass:"money"},[_vm._v(" 详情查看 ")]):_vm._e()]),_c('div',{staticClass:"address"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.originPlace,"placement":"top-start"}},[_c('i',{staticClass:"hy-icon-shouhuodizhi"})]),_vm._v(" "+_vm._s(item.originPlace)+" ")],1),(
                  (item.stockCount == 0 || item.goodsStatus == 1) &&
                  item.ifConsultRealityCount == 0
                )?_c('div',{staticClass:"display"},[_c('div',{staticClass:"text"},[_vm._v("已过期")])]):_vm._e()])}),0)]),_c('div',{staticClass:"pagination"},[_c('el-row',{staticStyle:{"height":"50px"},attrs:{"type":"flex","justify":"end","align":"middle"}},[_c('el-pagination',{attrs:{"background":"","layout":"total,  prev, pager, next, jumper","total":_vm.get_dto.total,"current-page":_vm.get_dto.page,"page-size":_vm.get_dto.size,"page-sizes":[10, 20, 50],"pagerCount":5},on:{"current-change":_vm.changePage,"size-change":_vm.handleSizeChange}})],1)],1)]),_c('el-tab-pane',{attrs:{"label":"竞价采购","name":"third"}},[_c('div',{staticClass:"box-block"},[_c('div',{staticClass:"block"},_vm._l((this.jingjiacaigou),function(item,index){return _c('div',{key:index,staticClass:"divBlock",on:{"click":function($event){return _vm.jingjiacaigouClick(item)}}},[_c('div',{staticClass:"divBlock_header"},[_vm._v(_vm._s(item.enterpriseName))]),_c('div',{staticClass:"huowu"},[_c('div',{staticClass:"dun"},[_c('div',{staticClass:"goods-name",attrs:{"title":item.goodsName}},[_vm._v(_vm._s(item.goodsName))]),_c('div',{staticClass:"nums"},[_vm._v(_vm._s(item.stockCount)+"吨")])]),(
                    item.goodsCategoryList.length === 1 &&
                    item.goodsCategoryList[0].goodsPrice != '0.00'
                  )?_c('div',{staticClass:"money"},[_vm._v(" ￥"+_vm._s(item.goodsCategoryList[0].goodsPrice)+" ")]):_vm._e(),(
                    item.goodsCategoryList.length === 1 &&
                    item.goodsCategoryList[0].goodsPrice == '0.00'
                  )?_c('div',{staticClass:"money"},[_vm._v(" ￥面议 ")]):_vm._e(),(item.goodsCategoryList.length > 1)?_c('div',{staticClass:"money"},[_vm._v(" 详情查看 ")]):_vm._e()]),_c('div',{staticClass:"address"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.originPlace,"placement":"top-start"}},[_c('i',{staticClass:"hy-icon-shouhuodizhi"})]),_vm._v(" "+_vm._s(item.originPlace)+" ")],1),(item.goodsStatus == 4 || item.goodsStatus == 2)?_c('div',{staticClass:"display"},[_c('div',{staticClass:"text"},[_vm._v("已过期")])]):_vm._e()])}),0)]),_c('div',{staticClass:"pagination"},[_c('el-row',{staticStyle:{"height":"50px"},attrs:{"type":"flex","justify":"end","align":"middle"}},[_c('el-pagination',{attrs:{"background":"","layout":"total,  prev, pager, next, jumper","total":_vm.get_dto.total,"current-page":_vm.get_dto.page,"page-size":_vm.get_dto.size,"page-sizes":[10, 20, 50],"pagerCount":5},on:{"current-change":_vm.changePage,"size-change":_vm.handleSizeChange}})],1)],1)]),_c('el-tab-pane',{attrs:{"label":"竞价销售","name":"fourth"}},[_c('div',{staticClass:"box-block"},[_c('div',{staticClass:"block"},_vm._l((this.jingjiaxiaoshou),function(item,index){return _c('div',{key:index,staticClass:"divBlock",on:{"click":function($event){return _vm.jingjiaxiaoshouClick(item)}}},[_c('div',{staticClass:"divBlock_header"},[_vm._v(_vm._s(item.enterpriseName))]),_c('div',{staticClass:"huowu"},[_c('div',{staticClass:"dun"},[_c('div',{staticClass:"goods-name",attrs:{"title":item.goodsName}},[_vm._v(_vm._s(item.goodsName))]),_c('div',{staticClass:"nums"},[_vm._v(_vm._s(item.stockCount)+"吨")])]),(
                    item.goodsCategoryList.length === 1 &&
                    item.goodsCategoryList[0].goodsPrice != '0.00'
                  )?_c('div',{staticClass:"money"},[_vm._v(" ￥"+_vm._s(item.goodsCategoryList[0].goodsPrice)+" ")]):_vm._e(),(
                    item.goodsCategoryList.length === 1 &&
                    item.goodsCategoryList[0].goodsPrice == '0.00'
                  )?_c('div',{staticClass:"money"},[_vm._v(" ￥面议 ")]):_vm._e(),(item.goodsCategoryList.length > 1)?_c('div',{staticClass:"money"},[_vm._v(" 详情查看 ")]):_vm._e()]),_c('div',{staticClass:"address"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.originPlace,"placement":"top-start"}},[_c('i',{staticClass:"hy-icon-shouhuodizhi"})]),_vm._v(" "+_vm._s(item.originPlace)+" ")],1),(item.goodsStatus == 4 || item.goodsStatus == 2)?_c('div',{staticClass:"display"},[_c('div',{staticClass:"text"},[_vm._v("已过期")])]):_vm._e()])}),0)]),_c('div',{staticClass:"pagination"},[_c('el-row',{staticStyle:{"height":"50px"},attrs:{"type":"flex","justify":"end","align":"middle"}},[_c('el-pagination',{attrs:{"background":"","layout":"total, prev, pager, next, jumper","total":_vm.get_dto.total,"current-page":_vm.get_dto.page,"page-size":_vm.get_dto.size,"page-sizes":[10, 20, 50],"pagerCount":5},on:{"current-change":_vm.changePage,"size-change":_vm.handleSizeChange}})],1)],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  // 查询企业收入/支出收入柱状图
  histogram7Day: "/trade/tradeGoods/histogram7Day",
  // 获取总收入和总支出
  totalInAndOut: "/trade/tradeGoods/totalInAndOut",
  // 交易记录
  transactionList: "/system/sysUser/tradeRecordPage",
  //交易记录导出
  tradeRecordExport:'/system/sysUser/tradeRecordExportForUser',
  // 商品列表查询
  headerPage: "/trade/tradeGoods/page",
  // 订单分页列表查询
  page: "/trade/tradeOrder/page",
  // 获取企业认证详情
  getCompanyAuthDetail: "/system/authCompany/detailAuthCompanyByEnterpriseId",
  // 获取合同模板列表
  listTemplate: "/trade/tradeOrder/contract/listTemplate",
  // 获取合同列表
  listContract: "/trade/tradeOrder/contract/listContract",
  // 上传合同模板
  addTemplate: "/trade/tradeOrder/contract/addTemplate",
  // 修改合同模板
  editTemplate: "/trade/tradeOrder/contract/editTemplate",
  // 设置交易密码
  setPassword: "/system/sysUser/setPassword",
  //新设置交易密码
  setPwd:'/finance/financeWallet/setPwd',
  // 检查密码
  checkPassword: "/system/sysUser/checkPassword",
  // 发送手机验证码
  sendSmsCode: "/third/third/sendSmsCode",
  //查询商品订单数
  orderNum:"/trade/tradeGoods/orderNum",
  //获取单点登录地址
  getLoginUrl:'/trade/azt/getLoginUrl',
  //获取签章列表
  getSealComposite:'/trade/azt/getSealComposite',
  //签章回调(修改签章状态)
  getSealCompositeCallback:'/trade/tradeOrder/contract/updateSignatureStatus?documentId=',
  //订单合同详情查询
  getContractState:'/trade/tradeOrder/contract/detailByOrderId',
  //文件下载接口
  getContractOSSFile:'/third/third/ossGetFile/',
  // 获取交易明细校验码
  getFlowVerifyCode:'/finance/financeWallet/getFlowVerifyCode',
  // 查询客户
  getUserlist:'/system/sysUser/getAllEnterprise',
  // 查询账号客户
  getAccountCustomers:'/trade/trade/customerRelationship/getCustomersByUserId',
  // 删除客户关系
  deleteCustomer:'/trade/trade/customerRelationship/deleteCustomer',
  // 添加客户关系
  addCustomer:'/trade/trade/customerRelationship/addCustomer',
  // 查询所有未绑定客户
  getUnBindCustomerEnterprise:'/trade/trade/customerRelationship/getUnBindCustomerEnterprise',
};

import url from "./url/userCenter.js";
import http from "@/utils/request";
// //查询企业收入/支出收入柱状图
export const getHistogram7Day = (params) =>
  http.get(url.histogram7Day, { params });
// 获取总收入和总支出
export const getTotalInAndOut = (data) => http.get(url.totalInAndOut, data);
// 获取交易记录
export const getTransactionList = (data) =>
  http.post(url.transactionList, data);
// 获取交易记录关注列表
// 获取交易明细校验码
export const getFlowVerifyCode = data => http.post(url.getFlowVerifyCode, data);
export const getHeaderPage = (data) => http.post(url.headerPage, data);
//交易记录导出
export const tradeRecordExport = (data) => http.postquerys(url.tradeRecordExport, data)
// 获取交易记录关注列表
export const getPage = (data) => http.post(url.page, data);
// 获取企业详情
export const getCompanyAuthDetail = (data) =>
  http.get(url.getCompanyAuthDetail, data);
// 获取合同模板列表
export const getListTemplate = (data) => http.post(url.listTemplate, data);
// 获取合同列表
export const getListContract = (data) => http.post(url.listContract, data);
// 上传合同模板
export const getAddTemplate = (data) => http.post(url.addTemplate, data);
// 修改合同模板
export const editTemplate = (data) => http.post(url.editTemplate, data);
// 设置交易密码
export const setPassword = (data) => http.post(url.setPassword, data);
// 新设置交易密码
export const setPwd = (data) => http.post(url.setPwd, data);
export const setPwdd = (data) => http.post('/system/sysUser/setPassword', data);
// 检查密码
export const checkPassword = (data) => http.post(url.checkPassword, data);
// 发送验证码
export const sendSmsCode = (data) => http.post(url.sendSmsCode, data);
//查询商品订单数
export const orderNum = (data) => http.get(url.orderNum, data);
//签章登录地址
export const getLoginUrl = (data) => http.post(url.getLoginUrl, data);
//获取签章列表
export const getSealComposite = (data) => http.post(url.getSealComposite, data);
//签章回调(修改签章状态)
export const getSealCompositeCallback = (data) => http.post(url.getSealCompositeCallback+`${data}`);
//订单合同详情查询
export const getContractState = (data) => http.post(url.getContractState, data);
  //文件下载接口
export const getContractOSSFile = (fileName, config) => http.get(url.getContractOSSFile + fileName, config);
// 获取客户
export const getUserlist = (data) => http.post(url.getUserlist, data);
// 获取账号客户
export const getAccountCustomers = (data) => http.post(url.getAccountCustomers, data);
// 删除客户关系
export const deleteCustomer = (data) => http.post(url.deleteCustomer, data);
// 添加客户关系
export const addCustomer = (data) => http.post(url.addCustomer, data);
// 查询所有未绑定客户
export const getUnBindCustomerEnterprise = (data) => http.post(url.getUnBindCustomerEnterprise, data);